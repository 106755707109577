<template>
    <mdb-pagination  class="d-flex justify-content-end">
        <mdb-page-nav prev :disabled="value === 1 ? true: false" @click.native="prevPage"></mdb-page-nav>
        <mdb-page-item :active="value === item" v-for="item in range(minPage, maxPage)" :key="item" @click.native="changePage(item)">{{item}}</mdb-page-item>
        <mdb-page-nav next :disabled="value === totalPages ? true: false" @click.native="nextPage"></mdb-page-nav>
    </mdb-pagination>
</template>
<script>
import { mdbPagination, mdbPageItem, mdbPageNav } from 'mdbvue';
  export default {
    name: 'tec-pagination',
    components: {
        mdbPagination, mdbPageItem, mdbPageNav,
    },
    props: {
      type: {
        type: String,
        default: 'default',
        description: 'Pagination type (primary|info|danger|default|warning|success)'
      },
      pageCount: {
        type: Number,
        default: 0,
        description: 'Pagination page count. This should be specified in combination with perPage'
      },
      perPage: {
        type: Number,
        default: 10,
        description: 'Pagination per page. Should be specified with total or pageCount'
      },
      total: {
        type: Number,
        default: 0,
        description: 'Can be specified instead of pageCount. The page count in this case will be total/perPage'
      },
      value: {
        type: Number,
        default: 1,
        description: 'Pagination value'
      }
    },
    computed: {
      paginationClass() {
        return `pagination-${this.type}`
      },
      totalPages() {
        if (this.pageCount > 0) return this.pageCount
        if (this.total > 0) {
          return Math.ceil(this.total / this.perPage)
        }
        return 1
      },
      pagesToDisplay() {
        if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
          return this.totalPages
        }
        return this.defaultPagesToDisplay
      },
      minPage() {
        if (this.value >= this.pagesToDisplay) {
          const pagesToAdd = Math.floor(this.pagesToDisplay / 2)
          const newMaxPage = pagesToAdd + this.value
          if (newMaxPage > this.totalPages) {
            return this.totalPages - this.pagesToDisplay + 1
          }
          return this.value - pagesToAdd
        } else {
          return 1
        }
      },
      maxPage() {
        if (this.value >= this.pagesToDisplay) {
          const pagesToAdd = Math.floor(this.pagesToDisplay / 2)
          const newMaxPage = pagesToAdd + this.value
          if (newMaxPage < this.totalPages) {
            return newMaxPage
          } else {
            return this.totalPages
          }
        } else {
          return this.pagesToDisplay
        }
      }
    },
    data() {
      return {
        defaultPagesToDisplay: 5
      }
    },
    methods: {
      range(min, max) {
        let arr = []
        for (let i = min; i <= max; i++) {
          arr.push(i)
        }
        return arr
      },
      changePage(item) {
        this.$emit('input', item)
      },
      nextPage() {
        if (this.value < this.totalPages) {
          this.$emit('input', this.value + 1)
        }
      },
      prevPage() {
        if (this.value > 1) {
          this.$emit('input', this.value - 1)
        }
      },
      handleresponse(){
          this.$emit('input', 'ok')
      }
    },
    watch: {
      perPage() {
        this.$emit('input', 1)
      },
      total() {
        this.$emit('input', 1)
      }
    }
  }
</script>
